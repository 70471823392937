export const CREATE_ORDER = "CREATE_ORDER"
export const ITEM_ORDERED = "ITEM_ORDERED"
export const ADD_QUANTITY = "ADD_QUANTITY"
export const UPDATE_SELECTED = "UPDATE_SELECTED"
export const SUBTRACT_QUANTITY = "SUBTRACT_QUANTITY"
export const ADD_ENGRAVE_DETAILS = "ADD_ENGRAVE_DETAILS"
export const REMOVE_ENGRAVE_DETAILS = "REMOVE_ENGRAVE_DETAILS"
export const UPDATE_DETAILS = "UPDATE_DETAILS"
export const SUBMIT_ORDER = "SUBMIT_ORDER"
export const UNSUBMIT_ORDER = "UNSUBMIT_ORDER"
export const CLEAR_ORDER = "CLEAR_ORDER"
